<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-route :items="items" @editForm="editForm"/>
            <modal-route @refresh="refresh" />
            <modal-route-edit :id="id" @refresh="refresh" />
        </div>
    </div>
</template>
<script>
import TableRoute from '@/views/component/Table/tableRoute.vue'
import ModalRoute from '@/views/component/Modal/ModalRoute/modalRoute.vue'
import ModalRouteEdit from '@/views/component/Modal/ModalRoute/modalRouteEdit.vue'
export default {
    components: {
        TableRoute,
        ModalRoute,
        ModalRouteEdit
    },
    data(){
        return {
            items: [],
            showPreloader: false,
            id: undefined,
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.refresh()
        // this.accessPage()
    },
    methods:{
        refresh(){
            this.showPreloader = true
            this.$http
                .get(`access-routes`)
                .then(res => {
                    this.items = res.data
                    this.$store.commit('pageData/setdataCount', this.items.length)
                    this.$store.commit('REFRESH_DATA', false)
                    this.showPreloader = false
                })
        },
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openModal(){
            this.$bvModal.show('accessEdit')
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get('access-routes')
                .then(res =>{
                    this.items = res.data
                    this.$store.commit('pageData/setdataCount', this.items.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }
}
</script>