<template>
    <div>
        <b-modal :id="$route.name + 'CreateModal'" title="Добавить разделы" size="sm" :no-close-on-backdrop="true" hide-footer no-enforce-focus @show="clearData">
            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Наименование</label>
                        <b-form-input v-model="item.name" id="basicInput" placeholder="Наименование"/>
                    </b-form-group> 
                </b-col>
                <b-col>
                    <b-form-group>
                        <label>Раздел</label>
                        <b-form-input v-model="item.route" id="basicInput" placeholder="Раздел"/>
                    </b-form-group>  
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="createAccess">
                        Добавить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    components: {
        vSelect,
        ToastificationContent
    },
    data(){
        return {
            item: {
                name: undefined,
            },
            submitButtonDisabled: false,
        }
    },
    methods: {
        createAccess(){
            this.submitButtonDisabled = true
            this.$http
                .post(`access-routes`, this.item)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.closeModal()
                    this.$emit('refresh')
                    // this.clearData()
                })
                .catch(err=> {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        closeModal(){
            this.$bvModal.hide(this.$route.name + 'CreateModal')
            this.clearData()
        },
        clearData(){
            this.item={
                name: undefined,
            }
        }
        
    },
}
</script>